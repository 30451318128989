import axios from 'axios';

class API {
    constructor({baseURL = '/api'} = {}) {
        this._baseURL = baseURL;
        this._axious = axios.create({
            // baseURL: baseURL
        })
    }

    async _get(endpoint, options) {
        try {
            const response = await this._axious.get(endpoint, options);
            return response.data;
        } catch (e) {
            throw {
                title: '',
                detail: e.response.data,
                status: e.response.status,
                error: new Error()
            };         
        }
    }
    
    async getCalendar() {
        return await this._get(`${this._baseURL}/calendar.json`);
    }

    async getSpriteManifest(date) {
        return await this._get(`${this._baseURL}/${date}/manifest.json`);
    }

    async getSpriteImage(date, filename, cb) {
        const image = await this.getImage(`${this._baseURL}/${date}/${filename}`)
        
        if (cb) {
            cb();
        }

        return image
    }

    async getTileImage(date, filename) {
        return await this.getImage(`${this._baseURL}/${date}/images/${filename}`)
    }

    async loadBLOB(url) {
        const response = await this._get(url, {responseType: 'arraybuffer'});
        
        return new Blob([response]);    
    }

    async getImage(url) {
        const blob = await this.loadBLOB(url);
        const URLObject = URL.createObjectURL(blob);

        return this._loadImage(URLObject);
    }

    BLOBtoImage(blob) {
        const URLObject = URL.createObjectURL(blob);

        return this._loadImage(URLObject);      
    }

    _loadImage(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', () => reject());
            img.src = url;
        });
    }

};

export {API};
export default new API();