
import moment from 'moment';
import Calendar from 'devextreme/ui/calendar';
import Toolbar from 'devextreme/ui/toolbar';
import Button from 'devextreme/ui/button';


import {API} from './api';
const api = new API({baseURL: process.env.API_URL});

moment.defaultFormat = 'YYYYMMDD';


export default class {
    constructor(container) {    
        this.init(container, true);
    }

    

    async init(container) {       
        this._aside = container.querySelector('aside');
        this._main = container.querySelector('main');
        this._thumbs = container.querySelector('.timeline-thumbs');
        this._thumb_size = this._thumbs.offsetHeight;
        

        this._c = container.querySelector('.calendar-wrapper');
        this._canvas = this.initCanvas(container.querySelector('.view-canvas-wrapper'));
        this._toolbar = this.initToolbar(container.querySelector('.toolbar-wrapper'));       
        
        this.initCalendar(container.querySelector('.calendar-wrapper'));
        this.initViewButtons(container.querySelector('.view-prev-wrapper'), container.querySelector('.view-next-wrapper'));
        this.initTimeline(container.querySelector('.timeline'));
        this.initKeyboardKeysHandler();
        this.updateLoop(process.env.UPDATE_INTERVAL);

        this._timer = null;
    }

    updateLoop(timeout = 60) {
        setTimeout(async () => {
            this.updateLoop(timeout);
            
            await this.updateCalendar();
            await this.updateImageData();
        }, timeout * 1000)
    }

// 
    getNextImageIndex() {
        return Math.min(this._selectedTileIndex + 1, this._tiles.length - 1)
    }

    getPrevImageIndex() {
        return Math.max(this._selectedTileIndex - 1, 0);
    }
  
    stopImageSequence() {
        if (this._timer) {
            clearTimeout(this._timer)
        }
        this.drawFullsizeImage(this._selectedTileIndex);
        this._isPlaying = false;
    }

    playImageSequence(newImageIndexFunc) {
        const playDelay = 1000;
        const playInterval = 50;
       
        const self = this;
        function play(timeout) {
            const newImageIndex = newImageIndexFunc();   

            if (newImageIndex === self._selectedTileIndex) {
                self.stopImageSequence()
                return
            }
          
            self._timer = setTimeout(() => play(timeout === playDelay ? playInterval : timeout), timeout);
            
            self.setTimelineItem(newImageIndex);
            self.scrollToTimelineThumb(newImageIndex);  
            self._isPlaying = timeout === playInterval;
        }

        play(playDelay);
    } 

    initKeyboardKeysHandler() {
        let rightArrowKeyPressed = false;
        let leftArrowKeyPressed = false;

        document.addEventListener('keydown', e => {
            if (e.code === 'ArrowRight') {
                if (rightArrowKeyPressed) {
                    return
                }

                rightArrowKeyPressed = true;
                this.playImageSequence(() => this.getNextImageIndex())
            } else if (e.code === 'ArrowLeft') {
                if (leftArrowKeyPressed) {
                    return
                }

                leftArrowKeyPressed = true;
                this.playImageSequence(() => this.getPrevImageIndex())
            }
        })

        document.addEventListener('keyup', e => {
            if (e.code === 'ArrowRight') {
                rightArrowKeyPressed = false;
                this.stopImageSequence();
            } else if (e.code === 'ArrowLeft') {
                leftArrowKeyPressed = false;
                this.stopImageSequence();
            }
        })
    }

    initViewButtons(prevButtonContainer, nextButtonContainer) {
        const self = this;

        new Button(document.createElement('div'),  {
            icon: 'chevronleft',
            stylingMode: 'text',
            hoverStateEnabled: true,
            activeStateEnabled: true,
            focusStateEnabled: false,
            onInitialized: (e) => {
                const elem = e.element;
                
                elem.addEventListener('mousedown', () => self.playImageSequence(() => self.getPrevImageIndex()));
                elem.addEventListener('mouseup', () => self.stopImageSequence());
                elem.addEventListener('mouseleave', () => self.stopImageSequence());

                prevButtonContainer.appendChild(elem);
                this._prevImageButton = e.component;
            }
        })

        new Button(document.createElement('div'),  {
            icon: 'chevronright',
            hoverStateEnabled: true,
            activeStateEnabled: true,
            focusStateEnabled: false,
            stylingMode: 'text',
            onInitialized: (e) => {
                const elem = e.element;
                
                elem.addEventListener('mousedown', () => self.playImageSequence(() => self.getNextImageIndex()));
                elem.addEventListener('mouseup', () => self.stopImageSequence());
                elem.addEventListener('mouseleave', () => self.stopImageSequence());

                nextButtonContainer.appendChild(elem);
                this._nextImageButton = e.component;
            }
        })
    }

    initCanvas(container) {
        const canvas = document.createElement('canvas');
        // square derived from responsive css width of 100%
        canvas.height = canvas.width;

        container.appendChild(canvas);

        return canvas
    }
    


    initToolbar(container, showSidebar = true, showThumbIndices = true, autoUpdate = true) {
        this._showThumbIndices = showThumbIndices;
        this._showSidebar = showSidebar;
        this._autoUpdate = autoUpdate;

        if (this._showThumbIndices) {
            this._thumbs.classList.add('with-indices');
        }
        
        this._toolbarCalendar = document.createElement('div');
        this._toolbarCalendar.classList.add('toolbar-calendar');
        this._timestamp = document.createElement('div');
        this._timestamp.classList.add('toolbar-date');
        this._toolbarCalendar.appendChild(this._timestamp);

        this._timestamp.addEventListener('click', e => {
          
            if (this._showSidebar) {
                return false;
            }
            if (this._c.parentNode !== this._calendarOrigin) {
                this._calendarOrigin.appendChild(this._c);
            } else {
                this._toolbarCalendar.appendChild(this._c);
            }
        })

        const self = this;
     
      
        return new Toolbar(document.createElement('div'), {
            onInitialized: (e) => {
                container.appendChild(e.element);
            },
            items: [{
                location: 'before',
                template: `<h1>Kiruna</h1>`
            }, {
                location: 'center',
                widget: 'dxButton',
                options: {
                    icon: 'chevronleft',
                    hint: 'Previous day',
                    disabled: true,
                    onInitialized: e => this._prevDateButton = e.component,
                    onClick: e => {
                        const prevCalendarDate = this.getPrevCalendarDate();

                        if (prevCalendarDate) {
                            this.setCalendarDate(prevCalendarDate);
                            this.setToolbarDate(prevCalendarDate);
                        }
                    }
                }
            }, {
                location: 'center',
                template: () => this._toolbarCalendar
            },  {
                location: 'center',
                widget: 'dxButton',
                options: {
                    icon: 'chevronright',
                    hint: 'Next day',
                    disabled: true,
                    onInitialized: e => this._nextDateButton = e.component,
                    onClick: e => {
                        const nextCalendarDate = this.getNextCalendarDate();

                        if (nextCalendarDate) {
                            this.setCalendarDate(nextCalendarDate);
                            this.setToolbarDate(nextCalendarDate);
                        }
                    }
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    text: 'Update',
                    stylingMode:  this._autoUpdate ? 'contained' : 'text',
                    onInitialized: e => this._autoUpdateButton = e.component,
                    onClick: e => this.setAutoUpdate(!this._autoUpdate)
                }               
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'smalliconslayout',
                    text: 'Grid',
                    stylingMode: this._grid_view ? 'contained' : 'text',
                    onInitialized: (e) => this._gridViewButton = e.component,
                    onClick: (e) => this.setGridView(!this._grid_view)
                }
            },
            ]
        });
    }

    initTimeline(container) {
        let scrollTimer = null;
        let grabTimer = null;
        this._preventTimelineScrollHandler = false;

        const self = this;

        let mouseMoveX = null;
        let mouseMoveDistance;
        let prevIndexOffset;

        container.addEventListener('mousedown', (e) => {
            if (this._grid_view) {
                return false;
            }

            document.onselectstart = () => false;

            container.classList.add('grab');
            mouseMoveX = e.clientX
            prevIndexOffset = 0;
        });
        container.addEventListener('mouseup', () => {
            if (this._grid_view) {
                return false;
            }
            
            document.onselectstart = () => true;

            container.classList.remove('grab');
            mouseMoveDistance = 0;
            mouseMoveX = null;
        });
        container.addEventListener('mousemove', e => {
            if (this._grid_view) {
                return false;
            }

            if (mouseMoveX === null) {
                return false;
            }


            const moveOffset = mouseMoveX - e.clientX;
            if (mouseMoveDistance * moveOffset >= 0) {
                mouseMoveDistance += moveOffset;
            } else {
                mouseMoveDistance = moveOffset;
                prevIndexOffset = 0;
            }

            // const newIndexOffset = this._tiles.length * mouseMoveDistance / container.offsetHeight / 2 >> 0;
            const newIndexOffset = mouseMoveDistance / 50 >> 0;
            if (newIndexOffset === prevIndexOffset) {
                return;
            }
            
            const newIndex = this._selectedTileIndex + (prevIndexOffset - newIndexOffset);
            this.setTimelineItem(newIndex);
            this.scrollToTimelineThumb(newIndex);

            clearTimeout(grabTimer);
            grabTimer = setTimeout(e => {
                this.drawFullsizeImage(self._selectedTileIndex);
            }, 250);
 
            prevIndexOffset = newIndexOffset;
            mouseMoveX = e.clientX;
        });

      
        let lastScrollEventTimestamp = null;
        container.addEventListener('scroll', e => {
            let now = (new Date()).getTime();   
            if (!lastScrollEventTimestamp || now - lastScrollEventTimestamp > 500) {
                lastScrollEventTimestamp = now;
                return false;
            }
            lastScrollEventTimestamp = now;

            if (this._grid_view || this._isPlaying) {
                this._preventTimelineScrollHandler = true;
            }
                        
            if (this._preventTimelineScrollHandler) {
                return;
            }

            try {
                clearTimeout(scrollTimer);
                scrollTimer = null;
            } catch (e) {;}
 
            const newTileindex = (e.target.scrollLeft / this._thumb_size) >> 0;
            const indexOffset = (container.offsetWidth / 2 / this._thumb_size) >> 0;
 
            this.setTimelineItem(newTileindex + indexOffset);
            scrollTimer = setTimeout(e => {
                self.drawFullsizeImage(self._selectedTileIndex);
            }, 250);
        });
    }


    async initCalendar(container) { 
        this._calendarOrigin = container.parentNode;
 
        const calendarManifest = await api.getCalendar();
        const calendarInitDate = moment(calendarManifest.range[1]).toDate();
    
        this._calendarManifest = calendarManifest;

        new Calendar(document.createElement('div'), {
            firstDayOfWeek: 1,
            min: moment(calendarManifest.range[0]).toDate(),
            max: moment(calendarManifest.range[1]).toDate(), 
            value: calendarInitDate,
            disabledDates: e => {
                const dateString = moment(e.date).format();
             
                return this._calendarManifest.missingDates.includes(dateString);
            },
            onValueChanged: async (e) => {
                this.setAutoUpdate(false);
                if (this._c.parentNode !== this._calendarOrigin) {
                    this._calendarOrigin.appendChild(this._c);
                }

                e.component.option('disabled', true);
                this._prevDateButton.option('disabled', true);
                this._nextDateButton.option('disabled', true);

                this.setToolbarDate(e.value);

                this._calendarDateString = moment(e.value).format();
                await this.loadData(this._calendarDateString);

                e.component.option('disabled', false);
                this._prevDateButton.option('disabled', this.getPrevCalendarDate(e.component) ? false : true);
                this._nextDateButton.option('disabled', this.getNextCalendarDate(e.component) ? false : true);     
            },
            onInitialized: e => {    
                this._calendar = e.component;

                container.appendChild(e.element);
              
                if (this.getPrevCalendarDate(e.component)) {
                    this._prevDateButton.option('disabled', false);
                }

                if (this.getNextCalendarDate(e.component)) {
                    this._nextDateButton.option('disabled', false);
                }

                this._calendarDateString = moment(calendarInitDate).format();
                this.setToolbarDate(calendarInitDate);
                this.loadData(this._calendarDateString);
            } 
        });

    }   


    getNextCalendarDate(calendar) {
        if (!calendar) {
            calendar = this._calendar;
        }
    
        let nextCalendarDay = moment(calendar.option('value')).add(1, 'd');
        let maxCalendarDay = calendar.option('max');

        while (this._calendarManifest.missingDates.includes(nextCalendarDay.format())) {
            nextCalendarDay.add(1, 'd');
        }

        if (nextCalendarDay > maxCalendarDay) {
            return false;
        }
          
        return nextCalendarDay.toDate();
    }

    getPrevCalendarDate(calendar) {
        if (!calendar) {
            calendar = this._calendar;
        }

        let prevCalendarDay = moment(calendar.option('value')).subtract(1, 'd');
        let minCalendarDay = calendar.option('min');

        while (this._calendarManifest.missingDates.includes(prevCalendarDay.format())) {
            prevCalendarDay.subtract(1, 'd');
        }

        if (prevCalendarDay < minCalendarDay) {
            return false;
        }
          
        return prevCalendarDay.toDate();
    }


    setCalendarDate(date) {
        this._calendar.option('value', date);
    }

    getCalendarDate() {
        return this._calendar.option('value');
    }

    getLastCalendarDate() {
        return this._calendar.option('max');
    }

    setToolbarDate(date) {
        this._timestamp.innerHTML = moment(date).format('YYYY-MM-DD');
    }

    async setAutoUpdate(enable = true) {
        if (this._autoUpdate === enable) {
            return;
        }

        this._autoUpdateButton.option({stylingMode: enable ? 'contained' : 'text'});

        if (enable) {
            await this.updateCalendar();
            
            const lastCalendarDate = this.getLastCalendarDate();
            
            if (lastCalendarDate.getTime() !== this.getCalendarDate().getTime()) {
                this.setCalendarDate(lastCalendarDate);
                this.setToolbarDate(lastCalendarDate);
            } else {
                //await this.updateImageData();
                const tileIndex = this._tiles.length - 1;
                this.setTimelineItem(tileIndex);
                this.scrollToTimelineThumb(tileIndex);

                if (!this._grid_view) {
                    this.drawFullsizeImage(tileIndex);
                }
            }
        }

        this._autoUpdate = enable;
    }


    async updateCalendar() {
        const manifest = await api.getCalendar()

        const min = moment(manifest.range[0]).toDate();
        const max = moment(manifest.range[1]).toDate();

        let calendarDate = null;
        if (!this._calendarManifest || this._autoUpdate) {
            calendarDate = max;
        }

        this._calendarManifest = manifest;
        this._calendar.option({
            min,
            max,
            value: calendarDate ? calendarDate : this.getCalendarDate()
        });
    }

    async updateImageData() {
        const date = moment(this.getCalendarDate()).format(); 

        const manifest = await api.getSpriteManifest(date);   
        const newTiles = manifest.sprites.reduce((filtered, sprite, index) => {
            if (index >= this._manifest.sprites.length || sprite.images.length > this._manifest.sprites[index].images.length) {
                for (let i = this._manifest.sprites[index].images.length; i < sprite.images.length; i++) {
                    
                    filtered.push({
                        ...sprite.images[i],
                        offset: [0, 0]
                    });
                } 
            }
            return filtered;
        }, []);
        this._manifest = manifest;

        if (!newTiles.length) {
            return
        }

        this.updateTimeline(newTiles);
    }

    resizeImage(image, newWidth, newHeight) {
        return new Promise((resolve, reject) => { 
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            canvas.toBlob(blob => resolve(api.BLOBtoImage(blob)));
        })  
    }

    async updateTimeline(newTiles) {
        const date = moment(this.getCalendarDate()).format();
    
        const tileImages = await Promise.all(
            newTiles.map(tile => api.getTileImage(date, tile.filename))
        );
        
        for (const [tileIndex, tile] of newTiles.entries()) {
            const tileImage = tileImages[tileIndex];

            this._spriteImages.push(
                await this.resizeImage(tileImage, this._manifest.tile.width, this._manifest.tile.height)
            );
            URL.revokeObjectURL(tileImage.src);

            this.appendTimelineItem(tile, this._spriteImages.length - 1);
        }
        
        if (this._autoUpdate && this._tiles.length) {
            const selectedTileIndex = this._tiles.length - 1;

            this.setTimelineItem(selectedTileIndex);
            this.scrollToTimelineThumb(selectedTileIndex);

            if (!this._grid_view) {
                this.drawFullsizeImage(selectedTileIndex);
            }
        }
    }

    createTimeline() {
        this._thumbs.querySelectorAll('*').forEach(elem => elem.remove());

        this._tiles = [];
        const self = this;
        for (const [spriteIndex, sprite] of this._manifest.sprites.entries()) {
            for (const tile of sprite.images) {
                this.appendTimelineItem(tile, spriteIndex);
            }
        }  

        this._selectedTileIndex = null;
        if (this._tiles.length) {
            let selectedTileIndex = this._tiles.length - 1;

            this.setTimelineItem(selectedTileIndex);
            this.scrollToTimelineThumb(selectedTileIndex);

            if (!this._grid_view) {
                this.drawFullsizeImage(selectedTileIndex);
            }
        }
    }

    appendTimelineItem(tile, spriteIndex) {
        this._tiles.push({
            tile,
            spriteIndex
        });

        const tileIndex = this._tiles.length - 1;

        this._preventTimelineScrollHandler = true;
        const wrapper = document.createElement('div');
        wrapper.classList.add('timeline-thumb-item-wrapper');
       
        const div = document.createElement('div');
        div.classList.add('timeline-thumb-item');
        wrapper.appendChild(div);

        this._thumbs.appendChild(wrapper);

        const canvas = document.createElement('canvas');
        canvas.height = div.offsetHeight;
        canvas.width = div.offsetWidth;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            this._spriteImages[spriteIndex], 
            tile.offset[0], tile.offset[1], 
            this._manifest.tile.width, this._manifest.tile.height,
            0, 0, 
            canvas.width, canvas.height
        );
        div.appendChild(canvas);
        
        
        wrapper.setAttribute('data-time', moment.unix(tile.timestamp).utc().format('HH:mm'))
        wrapper.setAttribute('data-index', tileIndex)
        wrapper.addEventListener('click', e => {
            const isSelected = wrapper.classList.contains('selected-tile');

            this.setTimelineItem(tileIndex);

            if (this._grid_view) {
                if (isSelected) {
                    this.setGridView(false);
                } 
            } else {
                this.drawFullsizeImage(tileIndex);
            }
        });

        return tileIndex;
    }


    async loadData(dateString) {
        this._main.setAttribute('data-loading', 0);

        this._selectedTileIndex = null;

        this._spriteImages = this._spriteImages || [];
        for (const img of this._spriteImages) {
            URL.revokeObjectURL(img.src)
        }
       
        this._manifest = await api.getSpriteManifest(dateString);
        let _loadedSprites = 0; 
        this._main.setAttribute('data-loading', _loadedSprites);

        this._spriteImages = await Promise.all(
            this._manifest.sprites.map(
                (sprite, id) => api.getSpriteImage(dateString, sprite.filename, () => {
                    _loadedSprites += 1;
                    this._main.setAttribute('data-loading', (_loadedSprites / this._manifest.sprites.length) * 100 >> 0);
                })
            )
        );

        this.createTimeline();
        
        setTimeout(() => this._main.removeAttribute('data-loading'), 500);

        this._main.tabIndex = -1;
        this._main.focus();
    }

    showThumbIndices(enable = true) {

    }

    showSidebar(enable = true) {
        if (enable) {
            this._aside.classList.remove('hidden')
        } else {
            this._aside.classList.add('hidden')
        }
    }

    setGridView(enable = true) {
        if (enable) {
            this._grid_view = true;
            this._main.classList.add('grid-view');
        } else {
            this._grid_view = false;
            this._main.classList.remove('grid-view');
            this.drawFullsizeImage(this._selectedTileIndex);
        }

        this._preventTimelineScrollHandler = enable;
        
        
        this.scrollToTimelineThumb(this._selectedTileIndex);
        this._gridViewButton.option('stylingMode', enable ?  'contained' : 'text');
    }

    setTimelineItem(index) {
        if (index >= this._tiles.length - 1) {
            this._nextImageButton.option({disabled: true});
        } else {
            this._nextImageButton.option({disabled: false})
        }
        
        if (index <= 0) {
            this._prevImageButton.option({disabled: true});
        } else {
            this._prevImageButton.option({disabled: false})
        }
        
        if (index > this._tiles.length - 1 || index < 0) {
            return;
        }

        if (index === this._selectedTileIndex) {
            return
        }
      
        this.selectTimelineThumb(index);
        this._selectedTileTimestamp = this._tiles[index].tile.timestamp;
    
        if (!self.grid_view) {
            this.drawTimelineThumb(index);
        }

        this.setAutoUpdate(this.getLastCalendarDate().getTime() === this.getCalendarDate().getTime() && index === this._tiles.length - 1)
    }



    scrollToTimelineThumb(tileIndex) {
        const thumbs = this._thumbs.childNodes;
        const thumb = thumbs[tileIndex];

        if (!thumb) {
            return
        }

        this._preventTimelineScrollHandler = true;
        thumb.scrollIntoView({inline: 'center'});
        window.scrollTo(0, 0);
        setTimeout(() => this._preventTimelineScrollHandler = false, 500);
    }

    selectTimelineThumb(tileIndex) {
        if (!this._tiles.length) {
            return;
        }

        const thumbs = this._thumbs.childNodes;
        if (this._selectedTileIndex !== null) {
            thumbs[this._selectedTileIndex].classList.remove('selected-tile');
        }

        thumbs[tileIndex].classList.add('selected-tile');
        this._selectedTileIndex = tileIndex;

    }

    setToolbarTimestamp(tileIndex) {
        const tile = this._tiles[tileIndex];
        this._timestamp.innerHTML = moment.unix(tile.tile.timestamp).utc().format('YYYY-MM-DD HH:mm UT');
    }


    drawTimelineThumb(tileIndex) {
        const tile = this._tiles[tileIndex];
        const ctx = this._canvas.getContext('2d');
    
        ctx.drawImage(
            this._spriteImages[tile.spriteIndex], 
            tile.tile.offset[0], tile.tile.offset[1], 
            this._manifest.tile.width, this._manifest.tile.height, 
            0, 0, 
            this._canvas.width, this._canvas.height
        );
    }


    async drawFullsizeImage(tileIndex) {
        const tile = this._tiles[tileIndex];
        const ctx = this._canvas.getContext('2d');

        if (this._tileImage) {
            URL.revokeObjectURL(this._tileImage.src)
        }
        this._tileImage = await api.getTileImage(this._calendarDateString, tile.tile.filename);
    
        ctx.drawImage(
            this._tileImage, 
            0, 0,
            this._tileImage.naturalWidth, this._tileImage.naturalHeight,
            0, 0, 
            this._canvas.width, this._canvas.height
        );     
    }
}


